import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { formatNumber } from '@shared/lib/numbers';
import ValueTrend from '@shared/ui/display/ValueTrend';
import { Body } from '@src/components/styled/Typography';
import SectionTitle from './SectionTitle';
import InfoContainer from './InfoContainer';
const UserPositionInfoContainer = styled.div(props => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    textWrap: 'nowrap',
    gap: '0 40px',
    borderBottom: `1px solid ${props.theme.customColors.button.pill.border}`,
}));
const SectionTitleText = styled(Body)((props) => ({
    fontSize: '16px',
    fontWeight: 500,
    color: `${props.theme.palette.text.primary}`,
    cursor: 'default',
    userSelect: 'none',
}));
const CurrentPosition = styled(Body)((props) => ({
    fontSize: '20px',
    fontWeight: 600,
    color: `${props.theme.palette.text.primary}`,
}));
const TotalCount = styled(CurrentPosition)(props => ({
    fontSize: 16,
    color: props.theme.palette.text.secondary,
}));
const PositionInfoDetails = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    textWrap: 'nowrap',
    gap: '16px 0',
    flexWrap: 'wrap',
    paddingBottom: '24px',
    borderBottom: `1px solid ${props.theme.customColors.button.pill.border}`,
}));
const SectionTitleContainer = styled.div(() => ({
    marginBottom: '24px',
}));
const PlInfoContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'end',
}));
const PlInfoTitle = styled.span(props => ({
    fontSize: props.theme.baseSize * 2,
    color: props.theme.palette.text.secondary,
}));
const PlValue = styled.span(() => ({
    fontSize: 14,
}));
const SubscriptionInfoContainer = styled.span(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
}));
const UserPosition = ({ currentPosition = undefined, totalCount = undefined, }) => {
    const currentPositionDisplayValue = formatNumber(currentPosition || 0, { compactNotation: true, maximumFractionDigits: 0 });
    const totalCountDisplayValue = formatNumber(totalCount || 0, { compactNotation: true, maximumFractionDigits: 0 });
    return (_jsxs(_Fragment, { children: [_jsx(CurrentPosition, { variant: 'caption', children: currentPositionDisplayValue }), _jsx(TotalCount, { variant: 'caption', children: `/ ${totalCountDisplayValue}` })] }));
};
const PlInfo = ({ title = undefined, value = undefined, currency = undefined, percentage = undefined, trend = undefined, }) => (_jsxs(PlInfoContainer, { children: [_jsx(PlInfoTitle, { children: title || '' }), _jsx(ValueTrend, { value: formatNumber(percentage || 0), trend: trend, addPercentage: true }), _jsx(PlValue, { children: `${currency || ''} ${formatNumber(value || 0)}` })] }));
const SubscriptionInfo = ({ duration, subscriptionDate, }) => (_jsxs(SubscriptionInfoContainer, { children: [_jsx("span", { children: duration }), _jsx("span", { children: subscriptionDate })] }));
const UserPositionInfo = ({ currency = undefined, subscriptionDuration = undefined, unrealizedPlPercentage = undefined, unrealizedPlTrend = undefined, unrealizedPlValue = undefined, realizedPlPercentage = undefined, realizedPlTrend = undefined, realizedPlValue = undefined, queuePosition = undefined, copiersCount = undefined, subscriptionDate = undefined, }) => {
    const { t } = useTranslation();
    return (_jsxs(UserPositionInfoContainer, { children: [_jsx(SectionTitleContainer, { children: _jsx(SectionTitle, { title: (_jsx(SectionTitleText, { variant: 'body1', children: t('portfolio_overview.sections.position.title', { ns: 'common' }) })), value: (_jsx(UserPosition, { currentPosition: queuePosition, totalCount: copiersCount })) }) }), _jsxs(PositionInfoDetails, { children: [_jsx(InfoContainer, { value: (_jsx(SubscriptionInfo, { duration: subscriptionDuration ? `${subscriptionDuration} ${t('periods.day', {
                                count: subscriptionDuration, ns: 'common',
                            })}` : '', subscriptionDate: subscriptionDate ?
                                `${format(subscriptionDate, 'dd.MM.yyyy')}` :
                                '' })), variable: t('portfolio_overview.sections.position.duration', { ns: 'common' }) }), _jsx(PlInfo, { title: t('portfolio_overview.sections.position.unrealized_pl', { ns: 'common' }), value: unrealizedPlValue, currency: currency, percentage: unrealizedPlPercentage, trend: unrealizedPlTrend }), _jsx(PlInfo, { title: t('portfolio_overview.sections.position.realized_pl', { ns: 'common' }), value: realizedPlValue, currency: currency, percentage: realizedPlPercentage, trend: realizedPlTrend })] })] }));
};
export default UserPositionInfo;
